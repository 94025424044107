import { createApp } from 'vue';
import './scss/main.scss'
import axios from "axios";
import Multiselect from 'vue-multiselect';


const api = axios.create({
    baseURL: "/api/",
    withCredentials: true,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken"
});

createApp({
    components: {
        Multiselect
    },
    delimiters: ["[[", "]]"],
    data() {
        return {
            subjects: [],
            isLoading: false,
            actualString: "",
            selectedSubject: null,
            showName: true,
            popupShow: false,
            nooptionsslotname: "noOptions",
            noresultslotname: "noResult",
            errors: {},
            form: {
                first_name: null,
                last_name: null,
                email: null,
                company: null,
                phone: null
            },
            paymentAnnually: true,
            pricing: {
                month: 1000,
                year: 750
            }
        }
    },
    computed: {
        fullMultiselectSettings: function () {
            return {
                label: "name",
                trackBy: "id",
                placeholder: "Název nebo IČO firmy",
                options: this.subjects,
                searchable: true,
                internalSearch: false,
                clearOnSelect: true,
                showLabels: false,
                preserveSearch: false,
                openDirection: "bottom",
                optionHeight: 60
            }
        },
        liteMultiselectSettings: function () {
            return {
                label: "name",
                trackBy: "id",
                placeholder: "",
                options: this.subjects,
                searchable: true,
                internalSearch: false,
                clearOnSelect: false,
                showLabels: false,
                preserveSearch: true,
                openDirection: "bottom",
                optionHeight: 60
            }
        },
        selectedSubjectName: function () {
            return this.selectedSubject ? this.selectedSubject.name : null
        },
        selectedSubjectIco: function () {
            return this.selectedSubject ? this.selectedSubject.organization_id : null
        },
        companyLink: function () {
            return this.selectedSubject ? `/dodavatel/${this.selectedSubject.slug}` : null
        },
        selectedPricing: function () {
            if (this.paymentAnnually) {
                return this.pricing.year
            }

            return this.pricing.month
        }
    },
    mounted: function () {
        this.changeDisplayPopup();
        this.displayNameOrSearch();
    },
    methods: {
        searchSubjects: function (query) {
            this.isLoading = true;
            this.subjects = [];
            this.actualString = query;
            if (query.length == 0) return

            api.get("ares/" + query)
                .then(r => {
                    if (query == this.actualString) {
                        ;
                        this.subjects = r.data;
                    }
                })
                .catch(e => console.log(e))
                .finally(() => {
                    if (query == this.actualString) {
                        this.isLoading = false;
                    }
                })
        },
        changeCompany: function () {
            this.showName = !this.showName;
            this.displayNameOrSearch();
        },
        displayNameOrSearch() {
            if (!this.$refs.company_name) { return }
            if (!this.$refs.company_search) { return }
            if (this.showName) {
                this.$refs.company_name.style.display = "initial";
                this.$refs.company_search.style.display = "none";
            }
            else {
                this.$refs.company_name.style.display = "none";
                this.$refs.company_search.style.display = "initial";
                this.focusSearchForm(false);
            }
        },
        focusSearchForm: function (scroll = true) {
            if (scroll)
                this.$el.scrollIntoView(true); {
            }
            this.$refs.search_form_focus.$el.querySelector('input').focus();
        },
        goToDetail: function (selectedOption = null, id = 0) {
            if (selectedOption != null) {
                this.selectedSubject = selectedOption;
            }
            window.location.href = this.companyLink;
        },
        showPopup: function () {
            this.popupShow = true;
            this.changeDisplayPopup();
        },
        hidePopup: function () {
            this.popupShow = false;
            this.changeDisplayPopup();
        },
        changeDisplayPopup: function () {
            if (!this.$refs.popup) { return }
            if (this.popupShow) {
                this.$refs.popup.style.display = "inherit";
            }
            else {
                this.$refs.popup.style.display = "none";
            }
        },
        copyLinkToClipboard: function() {
            this.$refs.popup_link.style.display = "inherit";
            var link = window.location.href;
            navigator.clipboard.writeText(link);

            setTimeout(function() {
                this.$refs.popup_link.style.display = "none";
            }.bind(this), 2000);
        },
        showErrors: function () {
            var first_name_error = "";
            var last_name_error = "";
            var email_error = "";
            var company_error = "";
            var phone_error = "";
            if (this.errors.first_name != null) {
                first_name_error = this.errors.first_name[0].message;
            }
            if (this.errors.last_name != null) {
                last_name_error = this.errors.last_name[0].message;
            }
            if (this.errors.email != null) {
                email_error = this.errors.email[0].message;
            }
            if (this.errors.company != null) {
                company_error = this.errors.company[0].message;
            }
            if (this.errors.phone != null) {
                phone_error = this.errors.phone[0].message;
            }
            this.$refs.first_name_error.innerHTML = first_name_error;
            this.$refs.last_name_error.innerHTML = last_name_error;
            this.$refs.email_error.innerHTML = email_error;
            this.$refs.company_error.innerHTML = company_error;
            this.$refs.phone_error.innerHTML = phone_error;
        },
        formChecked: function () {
            this.$refs.form.style.display = "none";
            this.$refs.form_success.style.display = "initial";
        },
        submitContactUs: function () {
            api.post(
                "contact-us/", this.form
            )
                .then(r => {
                    this.errors = {}
                    this.showErrors();
                    this.formChecked();
                })
                .catch(r => {
                    this.errors = r.response.data.errors;
                    this.showErrors();
                })
                .finally()
        }
    }
}).mount('#main_app');